<template>
  <v-window v-model="step" v-if="questionnaire" id="stepper" class="ma-10">
    <v-window-item :value="1">
      <ClarificationDescription />
      <div class="d-flex justify-center ma-4">
        <ArrowButton
          @click="next"
        >
          Fortsæt
        </ArrowButton>
      </div>
    </v-window-item>

    <!-- Ydelsesområder -->
    <v-window-item :value="2">
      <SectionEditor
        v-if="questions[0]"
        :questionnaire="questionnaire"
        :section="questions[0]"
        :level="1"
      />
      <div class="d-flex justify-end ma-4">
        <ArrowButton
          @click="next"
          :disabled="!questions[0] || incompleteSection(questions[0])"
        >
          Gå til ydelsesspecifikke afklaringer
        </ArrowButton>
      </div>
    </v-window-item>

    <!-- Ydelsesspecifikke spørgsmål -->
    <v-window-item :value="3">
      <SectionEditor
        v-if="questions[1]"
        :questionnaire="questionnaire"
        :section="questions[1]"
        :level="1"
      />

      <div class="d-flex justify-space-between ma-4">
        <v-btn
          class="secondary-button"
          @click="back"
        >Tilbage til ydelsesområder</v-btn>
        <ArrowButton
          @click="next"
          :disabled="!(questions[1]?.complete)"
        >
          Gå til tværgående afklaringer
        </ArrowButton>
        <LoadingSpinner :visible="isRefreshing" />
      </div>
    </v-window-item>

    <!-- Tværgående ydelser -->
    <v-window-item :value="4">
      <SectionEditor
        v-if="questions[2]"
        :questionnaire="questionnaire"
        :section="questions[2]"
        :level="1"
      />

      <div class="d-flex justify-space-between ma-4">
        <v-btn
          class="secondary-button"
          @click="back"
        >Tilbage til ydelsesspecifikke afklaringer</v-btn>
        <ArrowButton
          @click="next"
          :disabled="!(questions[2]?.complete)"
        >
          Gå til behovsafdækningsrapport
        </ArrowButton>
        <LoadingSpinner :visible="isRefreshing" />
      </div>
    </v-window-item>

    <!-- Behovsafdækningsrapport -->
    <v-window-item :value="5">
      <ClarificationReport :questions="questions" />
      <div class="d-flex justify-space-between ma-4">
        <v-btn class="secondary-button" @click="back">
          Tilbage til tværgående afklaringer
        </v-btn>
        <ArrowButton color="primary" @click="close"> Gem og luk </ArrowButton>
      </div>
    </v-window-item>
  </v-window>
</template>

<script setup lang="ts">
import {
  DeepQuestion,
  SectionEditor,
  DeepQuestionnaireRoot,
} from '@dims/components';
import { ref, watch } from 'vue';
import ClarificationReport from '@/components/Tender/Clarification/Report/ClarificationReport.vue';
import ClarificationDescription from './ClarificationDescription.vue';

const emit = defineEmits<{ close: [] }>();
const { questionnaire, questions, isRefreshing = false } = defineProps<{
  questionnaire: DeepQuestionnaireRoot,
  questions: DeepQuestion[],
  isRefreshing?: boolean }>();
const step = ref(1);

watch(
  step,
  () => {
    scrollToTop();
  },
);

function next() {
  step.value += 1;
}

function back() {
  step.value -= 1;
}

function close() {
  emit('close');
  step.value = 1;
}

function scrollToTop() {
  const element = document.getElementById('stepper');
  if (element) {
    element.scrollIntoView();
  }
}

function incompleteSection(question: DeepQuestion) {
  return !question.complete;
}

</script>
